<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-09-23 15:37:09
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-28 18:18:15
 * @FilePath: /mediatom-web/src/viewsForManage/Flow/components/BatchAddSupplierModal/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    v-model="addSupplierVisible"
    title="批量添加供应商"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    width="1082px"
  >
    <div class="btn-box">
      <a-button class="btn" type="primary" @click="handleAddSupplier">添加供应商</a-button>
    </div>
    <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
      <el-table :data="formquery.publisherVoList" style="width: 100%" class="table">
        <el-table-column label="操作" min-width="120">
          <div class="action-box" slot-scope="{ row, $index }">
            <a-button type="link" class="primary-text" @click="handleCopy(row)">复制</a-button>
            <a-button
              v-if="formquery.publisherVoList.length > 1"
              type="link"
              class="primary-text"
              @click="handleDelete($index)"
            >删除</a-button
            >
          </div>
        </el-table-column>
        <!-- 供应商名称 -->
        <el-table-column label="供应商名称" min-width="200">
          <template slot-scope="{ row, $index }">
            <a-form-model-item :prop="`publisherVoList[${$index}].name`" :rules="[{ required: true, message: '请输入供应商名称', trigger: 'blur' }]">
              <a-input style="width: 100%" v-model.trim="row.name" placeholder="请输入供应商名称"></a-input>
            </a-form-model-item>
          </template>
        </el-table-column>
        <!-- 自定义广告位 -->
        <el-table-column label="自定义广告位" min-width="140">
          <template slot-scope="{ row, $index }">
            <a-form-model-item :prop="`publisherVoList[${$index}].placeSts`">
              <a-switch size="small" :checked="row.placeSts === 'A'" @change="handleChangePlaceSts($event, row)"></a-switch>
            </a-form-model-item>
          </template>
        </el-table-column>
        <!-- 利润率 -->
        <el-table-column label="利润率" min-width="150" v-if="+isAdmin !== 2">
          <template slot-scope="{ row, $index }">
            <a-form-model-item :prop="`publisherVoList[${$index}].profitRate`" :rules="[{ required: true, message: '请输入利润率', trigger: 'blur' }]">
              <a-input style="width: 100%" type="number" v-model.trim="row.profitRate" placeholder="请输入利润率">
                <span slot="suffix">%</span>
              </a-input>
            </a-form-model-item>
          </template>
        </el-table-column>
        <!-- 状态 -->
        <el-table-column label="状态" min-width="80">
          <template slot-scope="{ row, $index }">
            <a-form-model-item :prop="`publisherVoList[${$index}].sts`">
              <a-switch size="small" :checked="row.sts === 'A'" @change="handleChangeSts($event, row)"></a-switch>
            </a-form-model-item>
          </template>
        </el-table-column>
        <!-- 接入类型 -->
        <el-table-column label="接入类型" min-width="140">
          <template slot-scope="{ row, $index }">
            <a-form-model-item :prop="`publisherVoList[${$index}].accessType`">
              <!-- <a-switch size="small" :checked="row.sts === 'A'" @change="handleChangeSts($event, row)"></a-switch> -->
              <a-radio-group v-model="row.accessType">
                <a-radio :value="0">
                  SDK
                </a-radio>
                <a-radio :value="1">
                  API
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </template>
        </el-table-column>
      </el-table>
    </a-form-model>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'
import { batchAddSupplier } from '@/apiForManage/flow/supplier'
export default {
  data () {
    return {
      layout: {
      },
      formquery: {
        publisherVoList: []
      },
      rules: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val) {
          this.formquery.publisherVoList = []
          this.handleAddSupplier()
          this.$nextTick(() => {
            this.$refs.ruleForm.clearValidate()
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    addSupplierVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      roleId: (state) => state.user.roleId,
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id,
      isAdmin: (state) => state.user.isAdmin,
      moneyType: (state) => (+state.user.cur === 1 ? '$' : '¥')
    })
  },
  methods: {
    handleChangePlaceSts (e, row) {
      row.placeSts = e ? 'A' : 'S'
    },
    handleChangeSts (e, row) {
      row.sts = e ? 'A' : 'S'
    },
    handleAddSupplier () {
      this.$set(this.formquery.publisherVoList, this.formquery.publisherVoList.length, {
        name: undefined,
        placeSts: 'A',
        profitRate: undefined,
        sts: 'A',
        accessType: 1
      })
    },
    handleCopy (row) {
      this.$set(this.formquery.publisherVoList, this.formquery.publisherVoList.length, { ...row })
    },
    handleDelete (index) {
      this.formquery.publisherVoList.splice(index, 1)
    },
    handleCancel () {
      this.addSupplierVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const resp = await batchAddSupplier(this.formquery)
          if (resp.code === 200) {
            this.$message.success('添加成功！')
            this.$emit('handleAddSuccess')
            this.addSupplierVisible = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.btn-box {
  margin-bottom: @smallMargin;
  .btn {
    width: 130px;
  }
}
.action-box{
  margin-bottom: 12px;
}
.table{
  border: 1px solid @compBorderColor;
  border-radius: @smallRadius;
}
::v-deep{
  td{
    padding-bottom: 0!important;
  }
}
</style>
