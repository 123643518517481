<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-16 15:20:57
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-27 12:20:24
 * @FilePath: /mediatom-web/src/viewsForManage/Flow/Supplier/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="supplier-container">
    <div class="supplier-head" v-if="+roleId !== 4 && role !== 'SUPER_ADMIN' && +isAdmin !== 2">
      <span>可用金额：{{ moneyType }}{{ numFormat(residueAmount, 3, '') }}</span>
      <a-button type="link" @click="handleSkipToCost">查看花费明细</a-button>
    </div>
    <div class="supplier-search">
      <div class="left">
        <m-select-more
          class="search-item"
          v-model="searchquery.userIdList"
          :allData="adminUserList"
          :searchById="true"
          :hasIcon="false"
          :showId="false"
          label="账户"
          :showSelectedPart="true"
          :hasSearch="true"
          width="450px"
          :canSelectAll="true"
          @change="handleChangeUser"
          v-if="role === 'SUPER_ADMIN'"
        />
        <m-select-more
          class="search-item"
          v-model="searchquery.publisherIdList"
          :allData="supplierList"
          :searchById="true"
          :hasIcon="false"
          :showId="true"
          label="供应商"
          :showSelectedPart="true"
          :hasSearch="true"
          width="450px"
          :canSelectAll="true"
          @change="handleSearch"
        />
        <m-select
          v-model="searchquery.sts"
          class="search-item"
          :dataList="stsList"
          label="状态"
          @change="handleSearch"
          :showSearch="false"
        />
      </div>
      <div class="right">
        <!-- <a-button type="primary" @click="handleEditSupplier(true)">添加供应商</a-button> -->
        <a-dropdown-button
          :trigger="['click']"
          type="primary"
          style="border-radius: 0px !important"
          @click="handleEditSupplier(true)"
        >
          <a-icon type="plus" />
          供应商
          <a-menu slot="overlay">
            <a-menu-item style="width: 130px" key="2" @click="handleBatchAddSupplier">
              <a-icon type="plus-square" />批量添加
            </a-menu-item>
          </a-menu>
        </a-dropdown-button>
      </div>
    </div>
    <div class="main">
      <a-spin :spinning="isLoading">
        <el-table :data="dataList" style="width: 100%">
          <el-table-column prop="name" label="供应商名称" min-width="150">
            <template slot-scope="{ row }">
              <div class="manager-text text-ellipsis" :title="row.name">
                {{ row.name }}
                <m-tips
                  v-if="+row.sourceType"
                  :content="`平台向所有用户提供自身的流量资源。您只需设置好广告源和预期利润，即可开始使用流量。请注意，使用前需在平台上预存资金，以便支付流量费用。`"
                  title=""
                  :width="200"
                ></m-tips>
              </div>
              <div class="id-row">
                <span class="id manager-text">{{ row.id }}</span>
                <CopySvg class="copy-icon percent-text" @click="copyId(row.id)" />
              </div>
            </template>
          </el-table-column>
          <!-- 只有平台运营和超管展示所属账户 -->
          <el-table-column

            prop="userName"
            label="所属账户"
            min-width="100"
            v-if="+roleId === 4 || role === 'SUPER_ADMIN'"
          >
            <template slot-scope="{ row }">
              <div class="manager-text text-ellipsis" :title="row.name">
                {{ row.userName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="placeSts" label="自定义广告位" min-width="100">
            <span class="manager-text" slot-scope="{ row }">
              {{ row.placeSts === 'A' ? '是' : '否' }}
            </span>
          </el-table-column>
          <el-table-column prop="placeNum" label="广告位个数" min-width="92">
            <span class="place-num primary-text" @click="skipToPlace(row)" slot-scope="{ row }">
              {{ row.placeNum }}个
            </span>
          </el-table-column>
          <el-table-column prop="requestList" label="7日流量请求数" width="240">
            <template slot-scope="{ row }">
              <RequestTableItem :row="row" />
            </template>
          </el-table-column>
          <el-table-column prop="advRequestList" label="7日广告请求" width="240">
            <template slot-scope="{ row }">
              <AdvRequestTableItem :row="row" />
            </template>
          </el-table-column>
          <!-- 只有平台运营展示合作状态 -->
          <el-table-column prop="cooperationSts" label="合作状态" min-width="80" v-if="+roleId === 4">
            <template slot-scope="{ row }">
              {{ row.cooperationSts === 'A' ? '合作中' : '暂停' }}
            </template>
          </el-table-column>
          <el-table-column prop="sts" label="状态" min-width="60">
            <template slot-scope="{ row }">
              <!-- 分享状态 运营角色 -->
              <a-switch
                size="small"
                v-if="+roleId === 4"
                :checked="row.shareSts === 'A'"
                @click="handleChangeSts(row)"
              ></a-switch>
              <!-- 供应商状态 其他角色 -->
              <a-switch size="small" v-else :checked="row.sts === 'A'" @click="handleChangeSts(row)"></a-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="220">
            <template slot-scope="{ row }">
              <span class="link-btn primary-text" @click="handleSkipToReport(row)">查看数据</span>
              <span class="link-btn primary-text" v-if="+isAdmin !== 2" @click="handleSkipToConfig(row)">配置详情</span>
              <!-- <span class="link-btn primary-text" v-if="+row.sourceType" @click="handleSkipToQuota">限额管理</span> -->
              <template v-if="!+row.sourceType">
                <m-icon-tips>
                  <EditSvg class="link-btn" @click="handleEditSupplier(false, row)" slot="icon" />
                  <template slot="title">编辑</template>
                </m-icon-tips>
                <m-icon-tips>
                  <DeleteSvg class="link-btn" @click="handleClickDeleteBtn(row)" slot="icon" />
                  <template slot="title">删除</template>
                </m-icon-tips>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <a-pagination
          class="pagination"
          v-if="dataList.length > 0"
          show-size-changer
          :current="searchquery.page"
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
          :pageSize="searchquery.limit"
        />
        <m-empty v-else style="height: 341px" imgHeight="176px">
          <template #description>暂无数据 </template>
        </m-empty>
      </a-spin>
    </div>
    <!-- 删除弹窗 -->
    <m-delete-modal
      :delVisible="delVisible"
      title="供应商"
      @modalCancel="delVisible = false"
      @handleDelete="handleDeleteItem"
    />
    <!-- 确认开启自定义广告位弹窗 -->
    <m-delete-modal
      :isDelete="false"
      :delVisible="openCustVisible"
      title="提示"
      desc="未开启自定义广告位，是否要开启?"
      @modalCancel="openCustVisible = false"
      @handleDelete="handleOpenCust"
    />
    <EditSupplierModel
      :visible="editSupplierVisible"
      :isAdd="isAdd"
      :supplierInfo="supplierInfo"
      @modalCancel="editSupplierVisible = false"
      @editSuccess="
        () => {
          editSupplierVisible = false
          getDataList()
          getSupplierList(this.searchquery.userIdList)
        }
      "
    />
    <BatchAddSupplierModal
      @handleAddSuccess="
        () => {
          handleSearch()
          getSupplierList()
        }
      "
      :visible="batchAddVisible"
      @modalCancel="batchAddVisible = false"
    />
  </div>
</template>

<script>
import { getSupplierPage, updateSupplier, deleteSupplier } from '@/apiForManage/flow/supplier'
import { copy } from '@/utils/string'
import CopySvg from '@/assets/icons/copy.svg?inline'
import EditSvg from '@/assets/icons/edit.svg?inline'
import DeleteSvg from '@/assets/icons/delete.svg?inline'
import EditSupplierModel from '../components/EditSupplierModal'
import BatchAddSupplierModal from '../components/BatchAddSupplierModal'
import mixGetList from '@/mixins/getListForManage'
import { mapState } from 'vuex'
import RequestTableItem from './RequestTableItem'
import AdvRequestTableItem from './AdvRequestTableItem'
import { numFormat } from '@/utils/dealNumber'
export default {
  components: {
    CopySvg,
    EditSupplierModel,
    EditSvg,
    DeleteSvg,
    RequestTableItem,
    AdvRequestTableItem,
    BatchAddSupplierModal
  },
  mixins: [mixGetList],
  data () {
    return {
      openCustVisible: false,
      delVisible: false,
      editSupplierVisible: false,
      batchAddVisible: false,
      isAdd: true,
      supplierInfo: {},
      searchquery: {
        publisherIdList: [],
        sts: undefined,
        userIdList: [],
        page: 1,
        limit: 10
      },
      total: 0,
      dataList: [],
      residueAmount: 0,
      isLoading: false
    }
  },
  async created () {
    this.role === 'SUPER_ADMIN' && (await this.getAdminUserList())
    await this.getSupplierList(this.searchquery.userIdList)
    this.initRouterQuery()
    this.handleSearch()
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      roleId: (state) => state.user.roleId,
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id,
      isAdmin: (state) => state.user.isAdmin,
      moneyType: (state) => (+state.user.cur === 1 ? '$' : '¥')
    })
  },
  methods: {
    numFormat,
    handleSkipToCost () {
      this.$router.push({ name: 'cost' })
    },
    handleBatchAddSupplier () {
      this.batchAddVisible = true
    },
    initRouterQuery () {
      const { params = {} } = this.$route
      params.page && (this.searchquery = { ...this.searchquery, ...params })
    },
    async handleChangeUser () {
      this.getSupplierList(this.searchquery.userIdList)
      this.handleSearch()
    },
    handleSkipToQuota () {
      this.$router.push({
        name: 'quota',
        params: {
          ...this.searchquery
        }
      })
    },
    handleSkipToReport (row) {
      const name = +this.isAdmin === 2 ? 'mediareport' : 'synthreport'
      const url = this.$router.resolve({
        name,
        query: {
          publisherId: row.id
        }
      })
      window.open(url.href, '_blank')
    },
    skipToPlace (row) {
      if (!row.placeNum) {
        this.supplierInfo = JSON.parse(JSON.stringify(row))
        this.openCustVisible = true
        return
      }
      this.$router.push({
        name: 'adplace',
        query: {
          supplierId: row.id
        }
      })
    },
    handleEditSupplier (isAdd, row) {
      this.isAdd = isAdd
      if (!isAdd) {
        this.supplierInfo = JSON.parse(JSON.stringify(row))
      }
      this.editSupplierVisible = true
    },
    handleSearch () {
      this.searchquery.page = 1
      this.getDataList()
    },
    async getDataList () {
      this.isLoading = true
      const { data = {} } = await getSupplierPage(this.searchquery)
      const { items = [], total = 0, residueAmount = 0 } = data
      this.dataList = items
      this.total = total
      this.residueAmount = residueAmount
      this.isLoading = false
    },
    // 复制方法
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    handleChangeSts (row) {
      const query = {
        ...row
      }
      if (this.roleId === 4) {
        query.shareSts = row.shareSts === 'A' ? 'S' : 'A'
      } else {
        query.sts = row.sts === 'A' ? 'S' : 'A'
      }
      updateSupplier(query).then((res) => {
        const { code } = res
        if (code === 200) {
          this.$message.success('修改成功')
          this.getDataList()
        }
      })
    },
    // 修改page
    handleChangePage (page) {
      this.searchquery.page = page
      this.getDataList()
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getDataList()
    },
    handleClickDeleteBtn (row) {
      this.delVisible = true
      this.supplierInfo = JSON.parse(JSON.stringify(row))
    },
    async handleOpenCust () {
      updateSupplier({
        ...this.supplierInfo,
        placeSts: 'A'
      }).then((res) => {
        this.$router.push({
          name: 'adplace',
          query: {
            supplierId: this.supplierInfo.id
          }
        })
      })
    },
    handleDeleteItem () {
      deleteSupplier({
        id: this.supplierInfo.id
      }).then((res) => {
        const { code } = res
        if (code === 200) {
          this.$message.success('删除成功')
          this.delVisible = false
          this.handleSearch()
        }
      })
    },
    handleSkipToConfig (row) {
      this.$router.push({
        name: 'adplaceconfig',
        query: {
          supplierId: row.id,
          supplierName: row.name
        },
        params: {
          ...this.searchquery
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.supplier-container {
  margin: 0 @mediumMargin @mediumMargin @mediumMargin;
  .supplier-head{
    background-color: #fff;
    padding: @smallMargin;
    margin-bottom: @mediumMargin;
    border: 1px solid @modelBorderColor;
    box-shadow: @modelShadow;
    border-radius: @mediumRadius;
    padding: @smallMargin;
  }
  .supplier-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      .search-item {
        margin-right: @compMargin;
        width: 220px;
      }
    }
    .right {
      button {
        width: 130px;
      }
    }
  }
  .main {
    background-color: #fff;
    padding: @smallMargin;
    margin-top: @mediumMargin;
    border: 1px solid @modelBorderColor;
    box-shadow: @modelShadow;
    border-radius: @mediumRadius;
    padding-top: calc(@smallMargin - @thPadding);
    .id-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .id {
        margin-right: 6px;
      }
      .copy-icon {
        cursor: pointer;
        min-width: 12px;
        min-height: 12px;
      }
    }
    .link-btn {
      margin-right: 12px;
      cursor: pointer;
    }
    .place-num {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .pagination {
      margin-top: @smallMargin;
    }
  }
}
</style>
